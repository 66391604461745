import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { BannerModalCategoryPage } from '../banner-modal-category/banner-modal-category.page';
import { RfqPage } from '../rfq/rfq.page';

@Component({
  selector: 'app-banner-modal-subcategory',
  templateUrl: './banner-modal-subcategory.page.html',
  styleUrls: ['./banner-modal-subcategory.page.scss'],
})
export class BannerModalSubcategoryPage implements OnInit {
  uid = this.authService.getUid(); 
  
  bannerPlacements: any[];
 items: any[];
  loading = true;
  error: any;
  dataReturned: any;
  bannerPlacementUID: any;


  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let bannerPlacementUID = this.navParams.get('bannerPlacementUID');
    let territoryID = this.navParams.get('territoryID');
    let itemID = this.navParams.get('itemID');
    let zipID = this.navParams.get('zipID');
    let todayID = this.navParams.get('todayID');
    console.log('territoryID', territoryID);
    console.log('itemID', itemID);
    console.log('todayID',todayID);
    console.log('zipID',zipID)

    this.apollo
    .watchQuery({
      query: gql`
        query items($itemID:ID)
{
  items(where:{itemID:$itemID}){
    itemID
    item
    image_path
    itemSubcategory{
      subcategoryID
      subcategory
    }
   
  }
}

      `,
             //variables: {uid:this.uid} , 
         variables:    {itemID:itemID},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.items = result?.data?.items;
      this.loading = result.loading;
      this.error = result.error;
    });

  }
  closeModal():void{
    this.modalController.dismiss();
}
async openModalBannerCategory(bannerPlacementUID, territoryID, itemID, subcategoryID,zipID,todayID) {
  const modal = await this.modalController.create({
    component: BannerModalCategoryPage,
    id:"banners-category",
    componentProps: {
      
      uid:this.uid,
      bannerPlacementUID:  bannerPlacementUID,
      territoryID:territoryID,
      itemID: itemID,
      subcategoryID: subcategoryID,
      zipID: zipID,
      todayID:todayID
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });
  return await modal.present();

}
  async openModalRFQ(bannerPlacementUID, territoryID, itemID, subcategoryID,zipID,todayID) {
    const modal = await this.modalController.create({
      component: RfqPage,
      id:"rfqpage" ,
      componentProps: {
        
        uid:this.uid,
        bannerPlacementUID:  bannerPlacementUID,
        territoryID:territoryID,
        itemID: itemID,
        subcategoryID: subcategoryID,
        zipID: zipID,
        todayID:todayID
        
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
    return await modal.present();
}
}