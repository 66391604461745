import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { BannerModalRfqPage } from '../banner-modal-rfq/banner-modal-rfq.page';
import { RfqPage } from '../rfq/rfq.page';



@Component({
  selector: 'app-banner-modal-category',
  templateUrl: './banner-modal-category.page.html',
  styleUrls: ['./banner-modal-category.page.scss'],
})
export class BannerModalCategoryPage implements OnInit {
  uid = this.authService.getUid(); 
  
  bannerPlacements: any[];
  subcategories: any[];
  loading = true;
  error: any;
  dataReturned: any;
  

  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let bannerPlacementUID = this.navParams.get('bannerPlacementUID');
    let territoryID = this.navParams.get('territoryID');
    let itemID = this.navParams.get('itemID');
    let zipID = this.navParams.get('zipID');
    let todayID = this.navParams.get('todayID');
    let subcategoryID = this.navParams.get('subcategoryID');
    console.log('territoryID', territoryID);
    console.log('itemID', itemID);
    console.log('subcategoryID', subcategoryID);
    console.log('todayID', todayID);
    console.log('zipID', zipID)

    this.apollo
    .watchQuery({
      query: gql`
        query subcategories($subcategoryID:ID)
{
  subcategories(where:{subcategoryID:$subcategoryID}){
    subcategoryID
    subcategory
   
   category{
      categoryID
      category
      image_path
    }
   
  }
}

      `,
             //variables: {uid:this.uid} , 
         variables:    {subcategoryID:subcategoryID},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.subcategories = result?.data?.subcategories;
      this.loading = result.loading;
      this.error = result.error;
    });

  }
  closeModal():void{
    this.modalController.dismiss();
}
async openModalBannerRFQ(bannerPlacementUID, territoryID, itemID, subcategoryID, categoryID, zipID, todayID) {
  const modal = await this.modalController.create({
    component:  RfqPage,
    id:"banners-rfq",
    componentProps: {
      
      uid:this.uid,
      bannerPlacementUID:  bannerPlacementUID,
      territoryID:territoryID,
      itemID: itemID,
      subcategoryID: subcategoryID,
      categoryID:categoryID,
      zipID: zipID,
      todayID:todayID
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });
  return await modal.present();
}
}
