import { Component, OnInit, Input } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';

import { ActivatedRoute, Router} from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';



@Component({
  selector: 'app-accept-detail',
  templateUrl: './accept-detail.page.html',
  styleUrls: ['./accept-detail.page.scss'],
})
export class AcceptDetailPage implements OnInit {
  @Input() dataArr: string;
  uid = this.authService.getUid(); 
  accepts: string[];
  acceptID:string
  loading = true;
  error: any;

  constructor( private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    private activatedroute:ActivatedRoute,
    private router: Router,
    
    private navParams: NavParams) {
    console.log(JSON.stringify(navParams.get('dataArr')));
   }

  ngOnInit() {
    let acceptID = this.navParams.get('acceptID');
console.log('acceptID',acceptID)
    this.apollo
      .watchQuery({
        query: gql`
           query Accept($acceptID:ID) {
          accepts(where:{acceptID:$acceptID}) 
        {
         acceptID
         task
         date_create
         homes{
           homeID
           email
           homeInfo{
             name_first
             name_last
           }
           homeAddress{
             street_number
             street
             city
             state
             zip
           }
         }
         acceptTask{
           task_description
           date_create
           taskItem{
             item
             image_path
           }
         }
         
         acceptCategory{
           category
         }
         acceptHome{
           email
           homeZip{
             town
           }

         }
   
    
     
  }
}
        `,
               //variables: {uid:this.uid} , 
           variables:    {acceptID: this.acceptID},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.accepts = result?.data?.accepts;
        this.loading = result.loading;
        this.error = result.error;
      });



   
  }
  closeModal():void{
    this.modalController.dismiss();
}
}
