import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { TodoDetailPage } from '../todo-detail/todo-detail.page';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';

const GET_TODOS = gql`
  query GetTodos($homeUID:ID) {
    todos(where:{homeUID:$homeUID, is_active:true}) {
      task_description
      task_detail
      todoUID
      is_active
      categoryID
      homeUID
      itemID
      subcategoryID
      territoryID
      zipID
    }}
`;
const deleteTodos = gql`
  mutation deleteTodos($todoUID:ID){
  deleteTodos (where:{todoUID:$todoUID}){
    nodesDeleted
   
  }
}

  
`;

//import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.page.html',
  styleUrls: ['./todo.page.scss'],
})
export class TodoPage implements OnInit {
  uid = this.authService.getUid(); 
  loading = true;
  error: any;
 
  todos:any[];
  dataReturned: any;
  private querySubscription: Subscription;

  

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    //private fb: FormBuilder, 
    ) {}


    




  ngOnInit() {

    this.querySubscription = this.apollo.watchQuery<any>({

      query: GET_TODOS,
      variables: {
        homeUID:this.uid
      },
    })
      .valueChanges
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        this.todos = data.todos;
      });
    
//above version with cont above


    this.apollo
    .watchQuery({
      query: gql`
        query todos($homeUID:ID)
{
  todos(where:{homeUID:$homeUID, is_active:true}){
    task_description
    task_detail
    task_why_now
    make_model
    years_old
    todoUID
    taskID
    is_active
    businessTypeID
   
  }
}

      `,
             //variables: {uid:this.uid} , 
         variables:    {homeUID:this.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todos = result?.data?.todos;
      this.loading = result.loading;
      this.error = result.error;
    });




  }
 closeModal():void{
    this.modalController.dismiss();
}
async openModalTodoDetail(todoUID, task_description, task_detail, task_why_now, make_model, years_old,businessTypeID,locationID) {
  const modal = await this.modalController.create({
    component: TodoDetailPage,
    id:"banners-subcategory",
    componentProps: {
      
      uid:this.uid,
     todoUID:  todoUID,
     task_description:task_description,
     task_detail: task_detail,
     task_why_now: task_why_now,
     make_model: make_model,
     years_old: years_old,
     businessTypeID:businessTypeID,
     locationID:locationID
    
     
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });
  return await modal.present();
}
async deleteTodos(todoUID) {
   
  this.apollo.mutate({
    mutation: deleteTodos,
    
    variables: {
    todoUID:todoUID,
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
     
console.log("Data Deleted");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  
  });
  //this.modalController.dismiss(null, null, "banners");
  //this.modalController.dismiss(null, null, "banners-subcategory");
  //this.modalController.dismiss(null, null, "banners-category");
  //this.modalController.dismiss(null, null, "banners-rfq");
  
   //this.router.navigateByUrl('seller/entry');






}

showToastDeleteTodo(event) {
this.toastController.create({
header: 'Congratulation',
message: 'You Have Deleted Your Todo',
position: 'middle',
cssClass: 'my-custom-class',
color: 'home',
duration: 4000

}).then((obj) => {
obj.present();

setTimeout(() => {
  console.log('Async operation has ended');
  event.target.complete();
}, 2000);

});


}
todo(): Observable<any> {
  return this.apollo.watchQuery<any>({
    query: GET_TODOS,
    variables: {
      //homeUID: $homeUID,
    },
  }).valueChanges;
  console.log('data');
}
}