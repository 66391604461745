//base

import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
//services
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
//import { NavParams } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { ActivatedRoute} from '@angular/router';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import {H3Index} from "h3-js";
import {H3IndexInput} from "h3-js";
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//mport {h3} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";
import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {isPentagon} from "h3-js";
import {getResolution} from "h3-js";
import {isValidCell} from "h3-js";


//newly added please see change log  https://h3geo.org/docs/library/migration-3.x/functions/
//https://h3geo.org/docs/library/migration-3.x/functions
//this map code from https://github.com/matthiasfeist/what-the-h3index/blob/main/src/index.js

//geoToH3 updated to latLngToCell
//h3ToGeo updated to cellToLatLng
//polyfill updated to polygonToCells
//h3GetResolution updated to getResolution
//h3IsPentagon updated to isPentagon
//kring updated to gridDisk
//h3IsValid updated to isValidCell

//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";

//mapbox

//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';
//placekey  https://github.com/Placekey/placekey-js
import {geoToPlacekey} from '@placekey/placekey';
import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//environment
//import { environment } from "../../../environments/environment";
//npm install geojson2h3
import geojson2h3 from 'geojson2h3';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
//H3
import  { h3SetToFeatureCollection } from 'geojson2h3';
import {cellToChildren} from "h3-js";

const now = new Date();

const GET_SUPPLIERS = gql`
   query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countsupplierTasks
              supplierHashtags{
              itemID
              item_tag
          }
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierCategory{
                categoryID
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }
              supplierBusinessType

                taskApp{
                itemID
                date_create
                task_description
                task_detail
                taskDaysAgo
                h39
                item_tag
                connectSupplierID
                declineSupplierID
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
`




const createHashtags = gql`

mutation createHashtags($itemID:ID,$supplierID:ID, $subcategoryID:ID, $businessTypeID:ID, $categoryID:ID,$dayID:ID,$weekID:ID,$uid:ID ){
  createHashtags(input:
    {
      itemID: $itemID
      supplierID:$supplierID                                                
			subcategoryID:$subcategoryID                   
			businessTypeID:$businessTypeID
			categoryID: $categoryID
			dayID: $dayID                           
			weekID:$weekID
      uid:$uid                                                
			                         
      
      items:{connect:{
         where:{
           node:{itemID:$itemID}
         }
       }}
    	
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{categoryID:$categoryID}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategoryID:$subcategoryID}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$uid}
         }
       }}
       
    }
    
  ) 
  
  {
   hashtags{
    hashtagID
    date_create
    	supplierID
      uid
    suppliers{
      supplierID
      email
    }
     itemID
    items{
      itemID
      item
      image_path
    }
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      subcategory{
        subcategory
          }
        }
        }
        
    
  }
 
    


`;

const createConnects = gql`
mutation createConnects($task_description:String,$supplierID:ID, $availibility:String, $note:String, $date_task_create:Date, $category:String, $subcategory:String, $taskID:ID,$dayID:ID,$weekID:ID,$uid:ID ){
  createConnects(input:
    {
      task_description:$task_description
      supplierID:$supplierID
      availibility:$availibility
      note:$note
      date_task_create:$date_task_create
      taskID:$taskID
      dayID:$dayID
      weekID:$weekID
      uid:$uid
      
    	tasks:{connect:{
        where: {
          node:{taskID:$taskID}
        }
        edge: {
         task_description:$task_description
         category:$category
         subcategory:$subcategory 
          
        }
      }}
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{category:$category}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategory:$subcategory}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$supplierID}
         }
       }}
       
    }
    
  ) 
  
  {
   connects{
    connectID
    	supplierID
      task
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      	tasks{
         task_description
          town
          category{
            category
            
          }
          territory{
            territory
          }
          subcategory{
            subcategory
          }
        }
        }
        
    
  }
 
    }
    


`;

const createDeclines = gql`
mutation createDeclines($task_description:String,$supplierID:ID, $availibility:String, $note:String, $date_task_create:Date, $category:String, $subcategory:String, $taskID:ID,$dayID:ID,$weekID:ID,$supplierUID:ID ){
  createDeclines(input:
    {
      task_description:$task_description
      supplierID:$supplierID
      availibility:$availibility
      note:$note
      date_task_create:$date_task_create
      taskID:$taskID
      dayID:$dayID
      weekID:$weekID
      supplierUID:$supplierUID
      
    	tasks:{connect:{
        where: {
          node:{taskID:$taskID}
        }
        edge: {
         task_description:$task_description
         category:$category
         subcategory:$subcategory 
          
        }
      }}
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{category:$category}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategory:$subcategory}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$supplierUID}
         }
       }}
       
    }
    
  ) 
  
  {
   declines{
    declineID
    	supplierID
      supplierUID
      task
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      	tasks{
         task_description
          town
          category{
            category
            
          }
          territory{
            territory
          }
          subcategory{
            subcategory
          }
        }
        }
        
    
  }
 
    }
    


`;

mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';

let h3IndexToHighlight = '';
//const h3Input = document.getElementById('zoomToIndex');
const h3Input = '872a10545ffffff';
@Component({
  selector: 'app-mapquest',
  templateUrl: './mapquest.page.html',
  styleUrls: ['./mapquest.page.scss'],
})
export class MapquestPage implements OnInit {
  uid = this.authService.getUid(); 

  segmentModel = "list";
  
  loading = true;
  error: any;
  suppliers: any[];
  Suppliers: any[];
  category:string[];
  cat:string[];
  sub:string;
  supplierCategory:any[];
  supplierSubcategory:[];
  bt:string[];
  tasks: any[];
  locations:string[];
  hextasks:string[];
  hextask:string[];
  territories:string[];
  declines:[];
connects:[]

  h39: string[];
  h391: string[];
  h39hex:string[];
  h39geo:string[];
  mytasks:any[];
  
  h39values:any[];
  h3index:any[];
  hexagons:string[];

  
  Category: string[];

  subcategory: string[];
  Subcategory: string[];
  business_type:string[];
  supplier:any;


  data:[];
  result:[];
  subscriptionTasksBT:any[];
  result1:any;
  prop:any[];
  mapLat:any[];
  mapLon:any[];
  lat:any;
  lon:any;
  todays:any;
  dayID:any;
  weekID:any;
  itemID:any;
  item_tag:any;
  email:any;
  
  
  

  private mapbox: mapboxgl.Map;
  private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private locationService: LocationService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    //private navParams: NavParams,
    
    //private fb: FormBuilder,
    private activatedroute:ActivatedRoute,
     
    ) {

        mapboxgl.accessToken = environment.mapboxgl.accessToken;
      }
      ngOnInit() {
        //let supplier=this.supplier
        let uid=this.uid;
        let now = new Date();
        let month = now.getMonth()+1;
        let year = now.getFullYear();
        let day = now.getDate(); 
       
        console.log('uid',uid);
        console.log('month',month);
        console.log('day',day);
        console.log('year',year);
        //console.log('supplier',supplier);


        this.apollo
        .watchQuery({
          query: gql`
             query todays
     {
     todays{
      todayID
      month{
        monthID
        month
      }
      
            day{
                day
                dayID
                date
                weekNumber
                month
                year
            }
              week{
                weekID
                week
                banner_placement{
                  bannerUID
                  bannerName
                  
                }
                promos_placement{
                  promoUID
                  promotionName
                }
               
      }
      }
     }
     
          `,
                 //variables: {uid:this.uid} , 
            // variables:    {uid: this.afAuth.auth.currentUser.uid},
             
        })
        .valueChanges.subscribe((result: any) => {
          this.todays = result?.data?.todays;
          this.loading = result.loading;
          this.error = result.error;
        });
        console.log('todays',this.todays)
    
        this.apollo
        .watchQuery({
          query: gql`
             query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countsupplierTasks
              supplierHashtags{
              itemID
              item_tag
          }
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierCategory{
                categoryID
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }
              supplierBusinessType

                taskApp{
                itemID
                task_description
                task_detail
                taskDaysAgo
                date_create
                count_connect
                count_decline
                connectSupplierID
                declineSupplierID
                declineSupplierUID
                
                h39
                item_tag
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
  

    
    
    `,
        //variables:    {uid: this.afAuth.auth.currentUser.uid},
            variables:    {uid: this.uid},

    })
        .valueChanges.subscribe((result: any) => {
          this.suppliers = result?.data?.suppliers;
          this.loading = result.loading;
          this.error = result.error;
        });
        
  
      }  
      

      segmentChanged(event){
        console.log(this.segmentModel);
        
        console.log(event);
      }
      AddConnectTasks(taskID,category,subcategory,territoryID,supplierID,uid,dayID,weekID ) {
        console.log("territories",territoryID);
        console.log("category",category);
        console.log("taskID",taskID);
        console.log("suppliers",this.suppliers);

    
      
            this.apollo.mutate({
            mutation:  createConnects,
            variables: {
            
            taskID:taskID,
            territoryID:territoryID,
            category:category,
            subcategory:subcategory,
            supplierID:supplierID,
            uid:uid,
            dayID:dayID,
            weekID:weekID
            },

            //newly added to test update function

           refetchQueries:[
             {
               query:gql`
              
              query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) 
              {
             supplierID
             }
            }
              
              
              `
              }]
      

          
            }).subscribe(({ data }) => {
            //console.log('id: ', id);
            console.log('territory: ', this.territories);
            console.log('sub: ', this.subcategory);
            console.log('data',data);
        
            }, (error) => {
            console.log('there was an big ass error sending the query', error);
            });
          }
        
        
        AddDeclineTasks(taskID,category,subcategory,supplierID,uid,dayID,weekID,supplierUID ) {
    
          console.log("category",category);
          console.log("taskID",taskID);
      
        
              this.apollo.mutate({
              mutation:  createDeclines,
              variables: {
              
              taskID:taskID,
              
              category:category,
              subcategory:subcategory,
              supplierID:supplierID,
              uid:uid,
              dayID:dayID,
              weekID:weekID,
              supplierUID:uid,
              },
  
              //newly added to test update function
  
             refetchQueries:[
               {
                 query:gql`
                
                query suppliers($uid:ID) {
                suppliers(where:{uid:$uid}) 
                {
               supplierID
               uid
               countsupplierTasks
               supplierTasks{
                task_description
                date_create
          taskID
          posted
          taskDaysAgo
          item_tag
          city_tag
          connectSupplierID
          count_connect
          count_decline
         
               }
               }
              }
                
                
                `
                }]
        
  
            
              }).subscribe(({ data }) => {
              //console.log('id: ', id);
              console.log('territory: ', this.territories);
              console.log('sub: ', this.subcategory);
              console.log('data',data);
          
              }, (error) => {
              console.log('there was an big ass error sending the query', error);
              });

            }
          AddHashtags(itemID, supplierID,subcategoryID, businessTypeID,categoryID,dayID,weekID,uid ) {
                
            this.apollo.mutate({
            mutation:  createHashtags,
            variables: {
            
            itemID:itemID,
            businessTypeID:businessTypeID,
            categoryID:categoryID,
            subcategoryID:subcategoryID,
            supplierID:supplierID,
            
            dayID:dayID,
            weekID:weekID,
            uid:uid
            
            },

            //newly added to test update function

           
      

          
            }).subscribe(({ data }) => {
            //console.log('id: ', id);
            
            console.log('data',data);
        
            }, (error) => {
            console.log('there was an big ass error sending the query', error);
            });
          }
            gotoJobs() {
              this.navService.gotoJobs();
             }
             gotoTask() {
               this.router.navigateByUrl('/task/:uuid');
             }
               async presentAlert() {
                 const alert = await this.alertController.create({
                   header: 'Thank You',
                   subHeader: 'Congratulation',
                   message: ' Please track progress on the Review Invites Tab Below',
                   buttons: ['OK'],
                 });
               
                 await alert.present();
                 let result = await alert.onDidDismiss();
                 console.log(result);

             
             }
             showToast() {
               this.toastController.create({
                 header: 'Congratulation',
                 message: 'You Have Now Connected With The Homeowner',
                 position: 'middle',
                 cssClass: 'my-custom-class',
                 color: 'home',
                 duration: 3000
                 
               }).then((obj) => {
                 obj.present();
               });
             
             }
            
           updateSuppliers(uid) {
             
             this.apollo
             .watchQuery({
               query: gql`
                  query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countsupplierTasks
              supplierHashtags{
              itemID
              item_tag
          }
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierCategory{
                categoryID
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }
              supplierBusinessType

                taskApp{
                itemID
                taskID
                task_description
                task_detail
                taskDaysAgo
                date_create
                count_connect
                count_decline
                declineSupplierUID
                h39
                item_tag
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }

       `,
             variables:    {uid: this.afAuth.auth.currentUser.uid},
       
       })
             .valueChanges.subscribe((result: any) => {
               this.suppliers = result?.data?.suppliers;
               this.loading = result.loading;
               this.error = result.error;
             });
             console.log('uid', uid);
         }
      
      ionViewDidEnter() {



        



        
      
        var supplier=this.supplier;
       
        var uid = this.afAuth.auth.currentUser.uid
        console.log('supplier',supplier);

        console.log('uid',uid);

        this.uid=this.uid;
        console.log('uid',uid);
        
        
       
        this.apollo
        .watchQuery({
              query: gql`
             query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) 
            {
              email
              supplierID
              countsupplierTasks
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
              }
              supplierCategory{
                categoryID
                category
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }

                subscriptionTasksBT{
                  taskID
                  date_create
        task_description
        task_detail
        category_tag
        subcategory_tag
        item_tag
        city_tag
        is_active
        count_connect
        count_decline
        taskDaysAgo
                h39
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
  

    
    
    `,
        //variables:    {uid: this.afAuth.auth.currentUser.uid},
            variables:    {uid: uid},

    })
        .valueChanges.subscribe((result: any) => {
          this.suppliers = result?.data?.suppliers;
          //this.tasks = result?.data?.suppliers.subscriptionTasksBT;
          this.loading = result.loading;
          this.error = result.error;
        });
        this.supplier = this.result;
        console.log('wtf',this.supplier)
        
        this.apollo
        .watchQuery({
          query: gql`
            query connects($supplierID:ID){
    connects(where:{supplierID:$supplierID},options: {sort:{date_create:DESC}}){
      date_create
      supplierID
      supplierUID
      uid
      connectTask{
        taskID
        task_description
        task_detail
        category_tag
        subcategory_tag
        item_tag
        city_tag
        is_active
        count_connect
        count_decline
        taskDaysAgo
      }
    }
  }
  

    
    
    `,
        variables:    {supplierID: this.afAuth.auth.currentUser.uid},
            //variables:    {supplierID: uid}

    })
        .valueChanges.subscribe((result: any) => {
          this.connects = result?.data?.connects;
          this.loading = result.loading;
          this.error = result.error;
        }); 

        //building Task Map Data
        //let supplier = this.suppliers;

        //testing
        //let tasks1 = supplier[0].subscriptionTasksBT;
        //let tasks =supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT);

        
        //let h39geo = cellToBoundary(tasks.h39,true);

        //console.log('task', task);
        //console.log('h39test', h39test);
        //console.log('h39hextest', h39hextest);
        //console.log('h39geotest', h39geotest);
        this.supplier=this.suppliers;
      

        //var supplier = this.supplier;
        console.log('supplier',this.suppliers);
       // console.log('tasks',tasks);




                //let tasks = this.suppliers.subscriptionTasksBT;

        //let tasks =supplier.map((subscriptionTasksBT)=> subscriptionTasksBT);
        let tasks=this.suppliers.map((taskApp)=> taskApp.taskApp);

        //let tasks =supplier.map((taskApp)=> taskApp.taskApp);
        console.log('tasks',tasks);
        let taskno = tasks[0];
        console.log('tasksno',taskno);

        let bob= taskno[0].h39;
        console.log('bob',bob);

        let h39 = this.suppliers[0].taskApp[0].h39;
        let h39hex = cellToBoundary(h39.h39,true);

        //end testing

        //let tasks =supplier.map((supplierTasks)=> supplierTasks.task);
        //let tasks =supplier.map((supplierTasks)=> supplierTasks.subscriptionTasksBT);

        let tasksnew =this.suppliers.map((taskApp)=> taskApp.taskApp);
        let h391 =this.suppliers.map((taskApp)=> taskApp.taskApp.map((tasks)=>tasks.h39));//let h39hex    = h39.map((h39)=>cellToBoundary(h39,true)); 
        let h39hex1 =this.suppliers.map((taskApp)=> taskApp.taskApp.map((h39)=>cellToBoundary(h39.h39,true)));
        let h39geo =this.suppliers.map((taskApp)=> taskApp.taskApp.map((tasks)=>cellToBoundary(tasks.h39,true)));

        let bt = this.suppliers.map((supplierBusinessType)=>supplierBusinessType.supplierBusinessType);
        let category =this.suppliers.map((supplierCategory)=> supplierCategory.supplierCategory.map((category)=>category.categoryID));
        let subcategory =this.suppliers.map((supplierSubcategory)=> supplierSubcategory.supplierSubcategory.map((subcategory)=>subcategory.subcategoryID));

        // set up map
        let mapLat =this.suppliers.map((supplierMap)=> supplierMap.supplierMap.map((geomap)=>geomap.latitude));//let h39hex    = h39.map((h39)=>cellToBoundary(h39,true)); 
        let mapLon =this.suppliers.map((supplierMap)=> supplierMap.supplierMap.map((geomap)=>geomap.longitude));//let h39hex    = h39.map((h39)=>cellToBoundary(h39,true)); 
        let lat = mapLat.toString();
        let lon = mapLon.toString();

        //let cat = JSON.stringify(category);
        let cat = category[0];
        let sub = subcategory[0];

        let geo = h39geo[0];
        let max =lon[0];

        console.log('maplon',mapLon);
        console.log('lon',lon);
        console.log('geo',geo);


        //let category =supplier.map((supplier)=>supplier.supplierCategory);

       // let h39hex1 =supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT.map((tasks)=>tasks.h39).map((tasks)=>cellToBoundary(tasks.h39,true)));
        //let h39hex =supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT.map((tasks)=>tasks.h39).map((tasks)=>cellToBoundary(tasks.h39,true)));

        //let hexagons  = h39hex.map((h39hex)=>h39hex.h39hex) =>hexpolygonToCells(h39hex,9));
        //let hexagons1  = supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT.map((h39hex)=>polygonToCells(h39hex.h39hex,9)));

        //let hexagons =supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT.map((h39)=>cellToBoundary(h39.h39,true).map((h39hex)=>polygonToCells(h39hex,9))));


        //let geojson   = hexagons.map((hexagons)=>h3SetToFeatureCollection(hexagons));
        
        //const hexBoundary = cellToBoundary(h39,true);
        //let h39geo    = h39.map((h39)=>cellToBoundary(h39,true));

        

        
        let julie = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]]
          coordinates: geo,
         
          }
        };
        console.log('julie',julie);

       
        console.log('suppler',supplier)
        console.log('tasks',tasks);
        console.log('category',category);    
        console.log('cat',cat);
        console.log('h39',h39);
        console.log('h39hex',h39hex);
        console.log('bt',bt);
        console.log('geo',geo);
        console.log('h39geo',h39geo);

        console.log('subcategory',subcategory);
        console.log('sub',sub);
        console.log('mapLat',mapLat);
        console.log('mapLon',mapLon);
        console.log('lat',lat);
        console.log('lon',lon);








        h39geo= h39geo;
        h39hex=h39hex;
        this.h39values=this.h39values;
        this.bt = this.bt;
        this.category = category;
        this.lat =lat;
        this.lon =lon;




       
       
          const map = new mapboxgl.Map({
          
          container: 'map',
          //style: 'mapbox://styles/mapbox/light-v9',

          style: 'mapbox://styles/mapbox/streets-v12',
          //style: 'mapbox://styles/mapbox/light-v10',

    
          //center: [-74.08097014877629,40.98385513546514],
          center: [lon,lat],
          zoom: 10.0,
          maxBounds: [[-170, -85], [170, 85]]
          });
    
          map.addControl(
            new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
            })
            );
  
           // add navigation controls \(zoom buttons, pitch & rotate\)
          map.addControl(new mapboxgl.NavigationControl());
          //full screen control
          //map.addControl(new mapboxgl.FullscreenControl({container: document.querySelector('body')}));

          
           
          map.on('load', () => {
            map.addSource('tiles-geojson', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: []
              }
            });
          
            map.addSource('tiles-centers-geojson', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: []
              }
            });

            //newly added for Task Map

            
              map.addSource("tasks", {
              type: "geojson",
              data: julie
              });

              map.addSource('tasks1', {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                'geometry': {
                  
                  'type': 'Polygon',
                 //' coordinates':[supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT.map((h39)=>cellToBoundary(h39.h39,true)))],
                  //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]],
                  //'coordinates':julie,
                  'coordinates':geo,
                  //'coordinates':h39hex,
  
                 
                  properties: {h39},
                  "h39": {h39},
                }
        
               }
                 
                 });
                 map.addLayer({
                  'id': 'blue',
                  'type': 'fill',
                  'source': 'tasks', // reference the data source
                  'layout': {},
                  'paint': {
                  'fill-color': '#0080ff', // blue color fill
                  'fill-opacity': 0.80
                  }
                  });
                 
                  // Add a black outline around the polygon.
                  map.addLayer({
                  'id': 'outline11',
                  'type': 'line',
                  'source': 'tasks', // reference the data source

                  'layout': {},
                  'paint': {
                   
                   
                  'line-color': '#0080ff',
                  'line-width': 11.0
                  }
                  });
                  console.log('h39hex',h39hex)

                  //add map layers
                  // Add a new layer to visualize the polygon.
                map.addLayer({
                'id': 'h39Jobs',
                'type': 'fill',
                'source': 'tasks', // reference the data source
                'layout': {},
                'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': 0.60
                }
                });
               
                
          //end of task map data
            map.addLayer({
              id: 'tiles',
              source: 'tiles-geojson',
              type: 'line',
              paint: {
                'line-color': '#000'
              }
            });
          
            map.addLayer({
              id: 'tiles-shade',
              source: 'tiles-geojson',
              type: 'fill',
              paint: {
                'fill-color': ['case', ['get', 'pentagon'], 'rgba(255,0,0,0.5)', 'rgba(0,0,0,0.0)']
              }
            });
          
            map.addLayer({
              id: 'tiles-centers',
              source: 'tiles-centers-geojson',
              type: 'symbol',
              layout: {
                'text-field': ['format', ['get', 'text'], { 'font-scale': .5 }],
                'text-offset': [0, -1],
              },
              paint: {
                'text-color': '#000',
                'text-color-transition': {
                  duration: 0
                },
                'text-halo-color': ['case', ['get', 'highlight'], '#0f0', '#fff'],
                'text-halo-width': 1,
                'text-halo-blur': 1
              }
            });
          
            updateTiles();
          });
          
          map.on('moveend', updateTiles);
          map.on('click', (e) => {
            const h3index = latLngToCell(e.lngLat.lat, e.lngLat.lng, mapZoomToH3Res(map.getZoom()))
            //newly added for aarbo
            const poly = cellToBoundary(h3index);//'872a10545ffffff'
            const hexagons = polygonToCells(poly, 9);
            const coordinates = cellsToMultiPolygon(hexagons, true);
            const h39values = JSON.stringify(hexagons);
           
            const lat = e.lngLat.lat;
            const lon = e.lngLat.lng;

            console.log('lat',lat);
            console.log('lon',lon);

            this.hexagons=hexagons;

            


            this.apollo
            .watchQuery({
              query: gql`
                 query locations($h39:[String])
                {
                  locations(where:{h39_IN:$h39}){
                  h39
                  locationTasks{
                  task_description
          }
        }
        }
    

        
        `,
            //variables:    {uid: this.afAuth.auth.currentUser.uid},
                variables:    {h39: hexagons},
    
        })
            .valueChanges.subscribe((result: any) => {
              this.locations = result?.data?.locations;
              
              this.loading = result.loading;
              this.error = result.error;
            });

            this.apollo
            .watchQuery({
              query: gql`
                query declines($supplierUID:ID){
        declines(where:{supplierUID:$supplierUID},options: {sort:{date_create:DESC}}){
          declineID
          date_create
          supplierID
          declineTask{
            taskID
            task_description
            date_create
            task_detail
            category_tag
            subcategory_tag
            item_tag
            city_tag
            is_active
            count_connect
            count_decline
            taskDaysAgo
          }
        }
      }
      
    
        
        
        `,
            variables:    {supplierUID: this.afAuth.auth.currentUser.uid},
                //variables:    {supplierID: uid}
    
        })
            .valueChanges.subscribe((result: any) => {
              this.declines = result?.data?.declines;
              this.loading = result.loading;
              this.error = result.error;
            });

            this.apollo
            .watchQuery({
              query: gql`
                 query hextasks($h39Index:[String],$categoryID:[ID])
                  {
                    tasks(where:{h39Index_IN:$h39Index, categoryID_IN:$categoryID},options: {sort:{date_create:DESC}}){  
                      taskID               
                      h39Index
                      task_description
                      task_detail
                      posted
                      taskDaysAgo
                      date_create
                      itemID
                      category_tag
                      subcategory_tag
                      item_tag
                      city_tag
                      zip_tag
                      declineSupplierID
                      declineSupplierUID
                      connectSupplierID
                      count_connect
                      count_decline
                      category{
                        categoryID
                        category
                      }
                      subcategory{
                          subcategoryID
                          subcategory
                        }
                        
        }
        }
        `,
           
                variables:    {h39Index: hexagons,categoryID:cat},
    
        })
            .valueChanges.subscribe((result: any) => {
              this.tasks = result?.data?.tasks;
              
              this.loading = result.loading;
              this.error = result.error;
              console.log('tasks',tasks);
              console.log('tasks-length',tasks.length);

            });
            console.log('hextasks',this.hextasks);

            console.log('h39values',h39values);
            console.log('h39values',h39values);
            console.log('locations',this.locations);
            console.log('category',this.category);


            console.log('h3index',h3index);
            console.log('poly',poly);//mapping points 6 points
            console.log('hexagons@',hexagons);//h39 index number at map resolutions
            console.log('coordinates',coordinates);//array of lat long coordinates
            console.log('h39values',h39values);

            console.log('plot',JSON.stringify(hexagons));

            







          

      
            
         //filter test
        const ages = [32, 33, 16, 40];
        let result1 = ages.filter(checkAdult);
        function checkAdult(age) {
        return age >= 18;
        }
        console.log('age',result1);
        this.result1=result1;

        //let mytasks = hexagons.filter(this.h39);
       //return h39

        //console.log('mytasks',mytasks);



           
          });
          
          
          
          
          function updateTiles() {
            var extentsGeom = getExtentsGeom();
            const mapZoom = map.getZoom()
            let h3res = mapZoomToH3Res(mapZoom)
          
            const h3indexes = extendH3IndexesByOne(polygonToCells(extentsGeom, h3res, true))
          
            map.getSource('tiles-geojson').setData(
              {
                type: 'FeatureCollection',
                features: h3indexes.map(getTileFeature)
              });
          
            map.getSource('tiles-centers-geojson').setData({
              type: 'FeatureCollection',
              features: h3indexes.map(getTileCenterFeature)
            });
          }
          
          function extendH3IndexesByOne(indexes) {
            const set = new Set()
            indexes.forEach(index => {
              gridDisk(index, 1).forEach(ringIndex => set.add(ringIndex))
            })
            return Array.from(set)
          }
          
          function getExtentsGeom() {
            var e = map.getBounds();
            return [
              e.getSouthWest().toArray(),
              e.getNorthWest().toArray(),
              e.getNorthEast().toArray(),
              e.getSouthEast().toArray(),
              e.getSouthWest().toArray()
            ];
          }
          
          function getTileFeature(h3index) {
            const feature = geojson2h3.h3ToFeature(h3index, {
              pentagon: isPentagon(h3index),
            })
            fixTransmeridian(feature)
            return feature
          }
          
          function getTileCenterFeature(h3index) {
            var center = cellToLatLng(h3index)
            return {
              type: 'Feature',
              properties: {
                text: h3index + '\nRes: ' + getResolution(h3index),
                highlight: h3index === h3IndexToHighlight
              },
              geometry: {
                type: 'Point',
                coordinates: [center[1], center[0]]
              }
            };
          }
          
          function mapZoomToH3Res(zoom) {
            return Math.max(0, Math.floor((zoom - 3) * 0.8))
          }
          function h3ResToMapZoom(res) {
            return Math.ceil((res + 3) * 1.2)
          }
          
          /**************************** 
           * the follwing functions are copied from
           * https://observablehq.com/@nrabinowitz/mapbox-utils#fixTransmeridian
           ****************************/
          
          function fixTransmeridianCoord(coord) {
            const lng = coord[0];
            coord[0] = lng < 0 ? lng + 360 : lng;
          }
          
          function fixTransmeridianLoop(loop) {
            let isTransmeridian = false;
            for (let i = 0; i < loop.length; i++) {
              // check for arcs > 180 degrees longitude, flagging as transmeridian
              if (Math.abs(loop[0][0] - loop[(i + 1) % loop.length][0]) > 180) {
                isTransmeridian = true;
                break;
              }
            }
            if (isTransmeridian) {
              loop.forEach(fixTransmeridianCoord);
            }
          }
          
          function fixTransmeridianPolygon(polygon) {
            polygon.forEach(fixTransmeridianLoop);
          }
          
          function fixTransmeridian(feature) {
            const { type } = feature;
            if (type === 'FeatureCollection') {
              feature.features.map(fixTransmeridian);
              return;
            }
            const { type: geometryType, coordinates } = feature.geometry;
            switch (geometryType) {
              case 'LineString':
                fixTransmeridianLoop(coordinates);
                return;
              case 'Polygon':
                fixTransmeridianPolygon(coordinates);
                return;
              case 'MultiPolygon':
                coordinates.forEach(fixTransmeridianPolygon);
                return;
              default:
                throw new Error(`Unknown geometry type: ${geometryType}`);
            }}
        
       
          }
  closeModal():void{
    this.modalController.dismiss();
    }
    showToastHashtag() {
      this.toastController.create({
        header: 'Congratulation',
        message: 'You Added the Hashtag ',
        position: 'middle',
        cssClass: 'my-custom-class',
        color: 'home',
        duration: 3000
        
      }).then((obj) => {
        obj.present();
      });
    }
      showToastConfirm() {
        this.toastController.create({
          header: 'Congratulation',
          message: 'You Have Now Shown Interest With The Homeowner',
          position: 'middle',
          cssClass: 'my-custom-class',
          color: 'success',
          duration: 3000
          
        }).then((obj) => {
          obj.present();
        });

      }
      showToastDecline() {
        this.toastController.create({
          header: 'Congratulation',
          message: 'You Have Now Declined the Task',
          position: 'middle',
          cssClass: 'my-custom-class',
          color: 'danger',
          duration: 3000
          
        }).then((obj) => {
          obj.present();
        });
}
refresher1(event) {
  this.toastController.create({
    header: 'Congratulation',
    message: 'You Have Updated Your Data',
    position: 'middle',
    cssClass: 'my-custom-class',
    color: 'pro',
    duration: 4000
    
  }).then((obj) => {
    obj.present();
  });
  setTimeout(()=>{
    event.target.complete();
    
  })
}
  refresher(event) {
    this.apollo
        .watchQuery({
          query: gql`
             query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countsupplierTasks
              
              
              
              
              supplierBusinessType

                taskApp{
                itemID
                task_description
                task_detail
                taskDaysAgo
                date_create
                count_connect
                count_decline
                connectSupplierID
                declineSupplierID
                declineSupplierUID
                
                h39
                item_tag
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
  

    
    
    `,
       // variables:    {uid: this.afAuth.auth.currentUser.uid},
            variables:    {uid: this.uid},

    })
        .valueChanges.subscribe((result: any) => {
          this.suppliers = result?.data?.suppliers;
          this.loading = result.loading;
          this.error = result.error;
        });
            this.toastController.create({
              header: 'Congratulation',
              message: 'You Have Updated Your Data',
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'pro',
              duration: 3000
              
            }).then((obj) => {
              obj.present();
            });
    setTimeout(()=>{
      event.target.complete();
      console.log('uid',this.uid);
    })
}
}
