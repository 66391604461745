import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { SubscriptionPage } from '../subscription/subscription.page';
import { BaseModalPage } from '../base-modal/base-modal.page';
import { Base1Page } from '../base1/base1.page';
import { ProfileProPage } from '../profile-pro/profile-pro.page';
import { MapperPage } from '../mapper/mapper.page';
import { BaseTerritoryPage } from '../base-territory/base-territory.page';
import { ProfileProListPage } from '../profile-pro-list/profile-pro-list.page';
import { ProfileProListDetailPage } from '../profile-pro-list-detail/profile-pro-list-detail.page';





@Component({
  selector: 'app-setup-modal',
  templateUrl: './setup-modal.page.html',
  styleUrls: ['./setup-modal.page.scss'],
})
export class SetupModalPage implements OnInit {

 //uid:any;
 
  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  suppliers: any[];
  dataReturned:any;

  segmentModel = "account";
  segment = 'account';
  private selectedSegment: String = 'account'
  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let supplierUID = this.navParams.get('uid');
   let uid = this.uid;
    console.log('supplierUID',supplierUID)
    this.apollo
                  .watchQuery({
                    query: gql`
                       
        query suppliers($uid:ID)
{
 suppliers(where:{uid:$uid}){
 supplierID
 email
 uid
 supplierBase{
      baseID
      uid
      supplierUID
    }
  supplierSubscription{
    subscriptionID
    supplierID
   
    
    plan{
      planID
      amount
      description
      territory
      business_type
    }
    subscriptionBase{
      baseID
      territory{
        territoryID
        territory
      }
      business_type{
        businessTypeID
        business_type
      }
    }
  }
 
  subscriptionTasks{
    is_active
    count_connect
    taskID
    task_description
    date_create
    item{
      item
      image_path
    }
    category{
      category
      image_path
    }
    subcategory{
      subcategory
    }
    zip{
      zip
      town
    }
    
  }
    
  }
}
  
  
            `,
                 // variables:    {uid: this.uid},
                 variables:    {uid: this.afAuth.auth.currentUser.uid}
            })
                  .valueChanges.subscribe((result: any) => {
                    this.suppliers = result?.data?.suppliers;
                    this.loading = result.loading;
                    this.error = result.error;
                  });
                  console.log('uid',this.uid)
  }
  closeModalSetup():void{
    this.modalController.dismiss(null, null, "setup");
    
}

async openModalBase() {
  const modal = await this.modalController.create({
    component: BaseModalPage,
    id:"base",
    componentProps: {
      uid:this.uid
     
   
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
async openModalSubscriptions(uid) {
  const modal = await this.modalController.create({
    component: SubscriptionPage,
    id:"subscription",
    componentProps: {
      
      uid:this.uid,
   
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}

async openModalMapper(uid) {
  const modal = await this.modalController.create({
    component: MapperPage,
    id:"subscription",
    componentProps: {
      
      uid:this.uid,
   
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
async openModalBase1(uid) {
  const modal = await this.modalController.create({
    component: BaseTerritoryPage,
    id:"baseTerritory",
    componentProps: {
      
      uid:this.uid,
      //baseID:baseID,
      
      
    }
  });
console.log('myuid',uid)
  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });
  console.log('myuid',uid);

  return await modal.present();
}
async openModalProfileHome(uid) {
  const modal = await this.modalController.create({
    component: ProfileProPage,
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}

async openModalProfileList(uid) {
  const modal = await this.modalController.create({
    component: ProfileProListPage,
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}


async openModalProfileListDetail(uid) {
  const modal = await this.modalController.create({
    component: ProfileProListDetailPage,
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
closeModel():void{
this.modalController.dismiss();
}
segmentChanged(event:any){
  console.log(event.target.value);
  this.selectedSegment= event.target.value
}
}
