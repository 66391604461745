import { Component, OnInit, Input } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';

import { ActivatedRoute, Router} from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { CartService } from '../../services/cart.service';
import { BehaviorSubject } from 'rxjs';
import { CategoryHomePage } from '../category-home/category-home.page';

@Component({
  selector: 'app-businesstype',
  templateUrl: './businesstype.page.html',
  styleUrls: ['./businesstype.page.scss'],
})
export class BusinesstypePage implements OnInit {
  uid = this.authService.getUid(); 
  businessTypes: string[];
  loading = true;
  error: any;
  dataReturned: string[];
  territoryID: any;
  businessTypeID: any;
  zipID: any;

  constructor( private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    private activatedroute:ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private navParams: NavParams) {
    console.log(JSON.stringify(navParams.get('dataArr')));
   }

  ngOnInit() {
    let businessTypeID = this.navParams.get('businessTypeID');
    let territoryID = this.navParams.get('territoryID');
    let zipID = this.navParams.get('zipID');
    console.log('bt',businessTypeID);
    console.log('territoryID',territoryID);
    console.log('zipID',zipID);
    
  this.apollo
    .watchQuery({
      query: gql`
       query BusinessType($businessTypeID:ID) {
          businessTypes(where:{businessTypeID:$businessTypeID}){
            business_type
            businessTypeID
            image_path
              category(options:{sort:{category:ASC}})
{
                categoryID
                category
                image_path
                image_path_header
                slug
              }
   
    
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {businessTypeID:businessTypeID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.businessTypes = result?.data?.businessTypes;
      this.loading = result.loading;
      this.error = result.error;
    });
  }
  closeModal():void{
    this.modalController.dismiss(null, null, "businesstypepage");
    //this.modalController.dismiss();
  }
  async openModal(categoryID,) {
    const modal = await this.modalController.create({
      component: CategoryHomePage,
      id:"categoryhomepage",
      componentProps: {
        
        uid:this.uid,
        categoryID:  categoryID,
        territoryID:  this.territoryID,
        businessTypeID:  this.businessTypeID,
        zipID: this.zipID
      }
    });
 
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
 
    return await modal.present();
  }
  

}
