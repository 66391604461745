import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Observable, from, of } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class NavService {
  user: Observable<any>;

  constructor(private afAuth: AngularFireAuth, 
    private db: AngularFirestore,
    private apollo: Apollo,
    private router: Router,
    private navCtrl: NavController) {

    }
    gotoProfileHome() {
      this.router.navigateByUrl('seller/profile-home');
    }

    gotoHome() {
      this.router.navigateByUrl('/home');
    }

    gotoJobs() {
      this.router.navigateByUrl('/jobs');
    }
    gotoList() {
      this.router.navigateByUrl('/buyer/list');
    }
    gotoLogin() {
      this.router.navigateByUrl('');
    }
    gotoSetUp() {
      this.router.navigateByUrl('/setup');
    }
    gotoSubscription() {
      this.router.navigateByUrl('buyer/subscription');
    }
    gotoSubscriptionDetail() {
      this.router.navigateByUrl('buyer/subscription_detail');
    }
    gotoDiscover() {
      this.router.navigateByUrl('buyer/discover');
    }
    gotoTerritory() {
      this.router.navigateByUrl('buyer/territory');
    }
    gotoAccepts() {
      this.router.navigateByUrl('buyer/accept');
    }
    gotoTerritoryDetail() {
      this.router.navigateByUrl('buyer/territory_detail');
    }
    gotoBusinessType() {
      this.router.navigateByUrl('buyer/business-type');
    };
    gotoInvites() {
      this.router.navigateByUrl('buyer/invites');
    };
    gotoSetupHome() {
      this.router.navigateByUrl('seller/setup-home');
    };
    gotoSellerEntry() {
      this.router.navigateByUrl('seller/entry');
    };
    gotoBusinessTypeSeller(businessTypeID) {
      this.router.navigateByUrl('seller/businesstype/businessTypeID');
    };
    gotoTaskHome() {
      this.router.navigateByUrl('seller/task-home');
    };
    gotoTrades() {
      this.router.navigateByUrl('seller/trades');
    };
    
    
}
