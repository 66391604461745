import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { OfferingModalPage } from '../offering-modal/offering-modal.page';



@Component({
  selector: 'app-promo',
  templateUrl: './promo.page.html',
  styleUrls: ['./promo.page.scss'],
})
export class PromoPage implements OnInit {

  uid = this.authService.getUid(); 
  
  promoPlacements: any[];
  loading = true;
  error: any;
  dataReturned: any;
  todays: any[];
  homes: any[];
  weekID: any[];
  territoryID: any[];
 

  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let weekID = this.navParams.get('weekID');
    let territory_number = this.navParams.get('territory_number');
    console.log('weekID',weekID);
    let territoryID = this.navParams.get('territoryID');
    console.log('territoryID',territoryID);
    let todayID = this.navParams.get('todayID');
    console.log('todayID',todayID);
    let zipID = this.navParams.get('zipID');
    console.log('zipID',zipID);
    let dayID = this.navParams.get('dayID');
    console.log('dayID',dayID);
   

    this.apollo
    .watchQuery({
      query: gql`
         query homes($uid:ID)
{
homes(where:{uid:$uid}){
  homeID
  username
  password
  email
  homeZip{
    zip
    zipID
    town
    zipTerritory{
      territoryID
      territory
      territory_number
      business_type{
            businessTypeID
            image_path
            business_type
              category{
                category
                categoryID
                  subcategory{
                    subcategory
                    subcategoryID
                      item{
                        itemID
                        item
                        image_path
                      }
                  }
                
              }
          }
    }
    
    tasks{
      taskID
      territoryID
      task_description
      task_detail
      homeID
      date_create
      taskInvites{
        
        supplierID
        
        
      }
      taskSuppliers{
        email
        supplierID
       
      }
      taskConnects{
        supplierID
        
        
      }
    }
    
      territory{
        territory
        territoryID
        territory_number
          classification{
            classification
              business_type{
                businessTypeID
                business_type
                  category{
                    category
                    categoryID
                      subcategory{
                        subcategory
                        subcategoryID
                          item{
                            itemID
                            item
                            image_path
                          }
                      }
                  }
              }
          }
          business_type{
            businessTypeID
            business_type
              category{
                category
                categoryID
                  subcategory{
                    subcategory
                    subcategoryID
                      item{
                        itemID
                        item
                        image_path
                      }
                  }
                
              }
          }
      }
  }
}
}


      `,
             //variables: {uid:this.uid} , 
         variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)

    this.apollo
    .watchQuery({
      query: gql`
         query todays
{
todays{
  todayID
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            promo_placement_count
            banners_place{
              territory_number
              bannerID
              banners{
                headline
              }
            }
            promoss_place{
              territory_number
              promoID
              promo{
                promotion
              }
            }
  }
  }
}


      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
   

  
  this.apollo
  .watchQuery({
    query: gql`
      query promoPlacements($weekID:ID)
{
promoPlacements(where:{weekID:$weekID}){
  promoID
 promoPlacementUID
 weekID
  week
  year
  territory_number
  promo{
    promoUID
    image_path
    promotion
    description
    
  }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {weekID: weekID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.promoPlacements = result?.data?.promoPlacements;
    this.loading = result.loading;
    this.error = result.error;
  });



  }
async openModalOffering(promoUID,todayID,territoryID, zipID,dayID) {
  const modal = await this.modalController.create({
    component: OfferingModalPage,
    id:"banners",
    componentProps: {
      
      uid:this.uid,
      promoUID:  promoUID,
      todayID:todayID,
      territoryID:territoryID,
      zipID:zipID,
      dayID:dayID
      
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();


}
closeModal():void{
  this.modalController.dismiss();
}

}
