import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute} from '@angular/router';
//used for poll & refresh
import { Subscription } from 'rxjs';
import { HomeTypePage } from '../home-type/home-type.page';

//placekey
import {geoToPlacekey} from '@placekey/placekey';
//H3
import geojson2h3, { h3SetToFeatureCollection } from 'geojson2h3';


import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//import {getHexBoundary} from "h3-js";

import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//keys to the kingdom
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";
//import {h3ToGeoBoundary} from "h3-js";

//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';

const GET_HOME_ADDRESS = gql`
  query GetHomeAddress($homeAddressID:ID) {
    homeAddresses(where:{homeAddressID:$homeAddressID}) {
      uid
      homeAddressID
      street_number
      street
      city
      state
      zip
      address_name
      
    }
  }
`

const createLocations = gql`
  mutation createLocations( $homeID:ID $homeAddressID:ID, $latitude: Float, $longitude: Float,$placekey:String, $h36:String,$h37:String,$h38:String,$h39:String,$h310:String,$h35:String,$h34:String,$h33:String,$h32:String,$h31:String,$h30:String ){
  createLocations(input:
    {
      homeID:$homeID
      homeAddressID:$homeAddressID
      latitude: $latitude
      longitude: $longitude
      placekey:$placekey
      h36:$h36
      h37:$h37
      h38:$h38
      h39:$h39
      h310:$h310
      h35:$h35
      h34:$h34
      h33:$h33
      h32:$h32
      h31:$h31
      h30:$h30
      
      
   
      
      

      home_address:{connect:{
        where: {
          node:{homeAddressID:$homeAddressID}
        }
        
      }}

      home:{connect:{
        where: {
          node:{homeID:$homeID}
        }
        
      }}
       
    }) 
  
  {
   locations{
    locationID
    homeID
    homeAddressID
    latitude
    longitude
    placekey
    h30
    h31
    h32
    h33
    h34
    h35
    h36
    h37
    h38
    h39
    h310
    geojson
    coordinates
    coord 
   
    home_address{
      street_number
      street
      city
      state
      zip
    }
    home{
      homeID
      email 
    }

   
    
  }
 
    }
    
  }
  
`;

@Component({
  selector: 'app-mylocation',
  templateUrl: './mylocation.page.html',
  styleUrls: ['./mylocation.page.scss'],
})
export class MylocationPage implements OnInit {
  

  loading = true;
  error: any;
  latitude: number;
  longitude :number;
  homeID: String;
  homeAddressID: String;
  placekey: String;
  h3Index: String;
  h30:any;
  h31:any;
  h32:any;
  h33:any;
  h34:any;
  h35:any;
  h36:any;
  h37:any;
  h38:any;
  h39:any;
  h310:any;
  poly:any;
  hexagons:any[];
  
  homeAddresses:any[];
  homes:any[];
  home:any[];
  coordinates:any;
  coor:any;
  coord:any[];
  dat0:any[];
  homeAdressQuery: QueryRef<any>;
  h3center:any;
  h39boundary:any[];
  boundary:any[];
  boundary1:any;
  h39hex:any[];
  geojson:any;
  dataReturned:any;
 locationID:any;



//mapbox parameters
  private mapbox: mapboxgl.Map;
  private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;
  private querySubscription: Subscription


  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    private fb: FormBuilder,
    private activatedroute:ActivatedRoute
     
    ) { mapboxgl.accessToken = environment.mapboxgl.accessToken;}

  ngOnInit() {
  }
  ionViewDidEnter() {

    let latitude = this.navParams.get('latitude');
    let longitude = this.navParams.get('longitude');
    let homeAddressID = this.navParams.get('homeAddressID');
    let homeID = this.navParams.get('homeID');
    //let h39boundary = this.navParams.get('h39boundary');
    let placekey=geoToPlacekey( latitude,longitude);
    let geo = placekeyToGeo(placekey);//[40.98415841509838, -74.07913882721337]
    //let hexBoundary = getHexBoundary(geo);
    let h30 =latLngToCell(latitude,longitude, 0);
    let h31 =latLngToCell(latitude,longitude, 1);
    let h32 =latLngToCell(latitude,longitude,2);
    let h33 =latLngToCell(latitude,longitude, 3);
    let h34 =latLngToCell(latitude,longitude, 4);
    let h35 =latLngToCell(latitude,longitude, 5);
    let h36 =latLngToCell(latitude,longitude, 6);
    let h37 =latLngToCell(latitude,longitude, 7);
    let h38 =latLngToCell(latitude,longitude, 8);
    let h39 =latLngToCell(latitude,longitude, 9);
    let h310 =latLngToCell(latitude,longitude, 10);
    let h39center = cellToLatLng(h39);
    let h39boundary = cellToBoundary(h39,true);
    let h39hex = cellToBoundary(h39);

    let boundary = JSON.stringify(h39boundary);
    let fruits = ["Banana", "Orange", "Apple", "Mango"];
    let text = fruits.toString();
    let boundary1 = h39boundary.toString();
    
    

//the keys to the kingdome
let h3Index = latLngToCell(40.984194, -74.079635, 9);
let poly = cellToBoundary(h39);//'872a10545ffffff'
let poly1 = cellToBoundary(h3Index);//'872a10545ffffff'
// Get all H3 indexes within a geographic polygon. Inclusion is determined by whether the center
// of a given cell falls within the polygon.
let hexagons = polygonToCells(h39hex,9);
// Get the multipolygon (array of polygons) for a set of H3 indexes. Indexes are expected to
// be unique and all at the same resolution

//this works for Mapbox**************************
let geojson =h3SetToFeatureCollection(hexagons);




let poly3 = cellsToMultiPolygon(hexagons);
// Get all neighbors within 1 step of the hexagon
const disk = gridDisk(h39, 1);
let disk1 = cellsToMultiPolygon(disk);


let coordinates = cellsToMultiPolygon(hexagons, true);
//let coor = JSON.stringify(coordinates);
let coor = JSON.stringify(coordinates);
//use parse to delete outside quotation marks
//let coord = JSON.parse(coor.slice(1,-1));
let coord = (coor.slice(1,-1));

    console.log('latitude',latitude);
    console.log('longitude',longitude);
    console.log('h39',h39);
    console.log('h39center',h39center);
    console.log('h30',h30);
    console.log('h31',h31);
    console.log('h32',h32);
    console.log('h33',h33);
    console.log('h34',h34);
    console.log('h35',h35);
    console.log('h36',h36);
    console.log('h37',h37);
    console.log('h38',h38);
    console.log('h39',h39);
    console.log('h39center',h39center);
    console.log('h39boundary',h39boundary);
    console.log('h39hex',h39hex);
    console.log('hexagons',hexagons);
    console.log('poly3',poly3);
    console.log('disk',disk);
    console.log('disk1',disk1);
    console.log('boundary',boundary);
    console.log('h3Index',h3Index);
    console.log('h310',h310);
    console.log('placekey',placekey);
    console.log('homeID',homeID),
    console.log('homeAddressID',homeAddressID);
    console.log('coordinates',coordinates);
    console.log('coor',coor);
    console.log('coord',coord);
    console.log('poly',[[[poly]]]);
    console.log('geo',geo);
    console.log('hexagons',hexagons);
    console.log('h39center',h39center);
    console.log('text',text);
    console.log('boundary',boundary);
    console.log('boundary1',boundary1);
    console.log('geojson',geojson);


    


    
    //console.log('coor',coor);
   
    this.locationID = this.locationID;
    this.geojson;
    this.homeAddressID=homeAddressID;
    this.h30 = h30;
    this.h31 = h31;
    this.h32 = h32;
    this.h33 = h33;
    this.h34 = h34;
    this.h35 = h35;
    this.h36 = h36;
    this.geojson =geojson;
    this.h37 = h37;
    this.h38 = h38;
    this.h39 = h39;
    this.h39boundary = h39boundary;
    this.h39hex = h39hex;
    this.boundary1 = boundary1;
    this.h310 = h310;
    this.latitude = latitude;
    this.longitude = longitude;
    this.placekey=placekey;
    this.homeID = homeID;
    this.coordinates=coordinates;
    this.coor = boundary;
    coord = coord;
    poly = poly
   
    console.log('coord',coord)

    //map
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v9',
        zoom: 15,
       // center: [-74.08097014877629,40.98385513546514]
       //below code from database fields
       center: [longitude,latitude]
        });


        // add navigation controls \(zoom buttons, pitch & rotate\)
        map.addControl(new mapboxgl.NavigationControl());

        map.on('load', () => {
          // Add a data source containing GeoJSON data.
          map.addSource('maine', {
          'type': 'geojson',
          'data': geojson
          
          });
        
         
             console.log('geojson',geojson)
              
              // Add a new layer to visualize the polygon.
              map.addLayer({
              'id': 'maine',
              'type': 'fill',
              'source': 'maine', // reference the data source
              'layout': {},
              'paint': {
              'fill-color': '#0080ff', // blue color fill
              'fill-opacity': 0.20
              }
              });
              // Add a black outline around the polygon.
              map.addLayer({
              'id': 'outline',
              'type': 'line',
              'source': 'maine',
              'layout': {},
              'paint': {
              'line-color': '#000',
              'line-width': 1
              }
              });
              });
            
            }
              async createLocations(homeID,homeAddressID,latitude,longitude,placekey,h36,h37,h38,h39,h310,h39boundary,h39hex,h35,h34,h33,h32,h31,h30) {
      
                this.apollo.mutate({
                  mutation: createLocations,
                  
                  variables: {
                 
                  homeID:homeID,
                  homeAddressID:homeAddressID,
                  latitude:latitude,
                  longitude:longitude,
                  placekey:placekey,
                  h30:h30,
                  h31:h31,
                  h32:h32,
                  h33:h33,
                  h34:h34,
                  h35:h35,
                  h36:h36,
                  h37:h37,
                  h38:h38,
                  h39:h39,
                  h310:h310,
                  h39boundary:h39boundary,
                  h39hex:h39hex,
                 
                  //geojson:geojson
                  
                  
                  
                },
                  
                }).subscribe(({ data }) => {
                  
               
                   console.log(data);
                   console.log('boundary1',this.boundary1)

              console.log("Form Submitted!");
              
              //this.router.navigateByUrl('seller/home-type');
              //this.router.navigateByUrl('seller/entry');

              //this.navCtrl.navigateForward('/setup'); 
               //this.router.navigate(['/app/setup/',this.id ]);
                 
                }, (error) => {
                  console.log('there was an big ass error sending the query', error);
                  
                 
                });
              }
              closeModalLocation():void{
                //this.modalController.dismiss();
                this.modalController.dismiss(null, null, "LocationPage");
              }
                async openModalHomeType(uid,homeAddressID,homeID,locationID,h30,h31,h32,h33,h34,h35,h36,h37,h38,h39,h310) {
                  const modal = await this.modalController.create({
                    component: HomeTypePage,
                    id:"home-type",
                    componentProps: {
                      
                      uid:uid,
                      homeAddressID:homeAddressID,
                      homeID:homeID,
                      locationID:locationID,
                      h30:h30,
                      h31:h31,
                      h32:h32,
                      h33:h33,
                      h34:h34,
                      h35:h35,
                      h36:h36,
                      h37:h37,
                      h38:h38,
                      h39:h39,
                      h310:h310,
                      
                      
                    }
                  });
                
                  modal.onDidDismiss().then((dataReturned) => {
                    if (dataReturned !== null) {
                      this.dataReturned = dataReturned.data;
                      //alert('Modal Sent Data :'+ dataReturned);
                    }
                  });
                
                  return await modal.present();

  }
}

