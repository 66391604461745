import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { CategoryHomePage } from '../category-home/category-home.page';

const createCategoryEvent = gql`
 mutation createCategoryEvent( $categoryID:ID,$territoryID:ID, $zipID:ID, $todayID:ID,$homeID:ID,$dayID:ID){
  createEvent_Home_Categories(input:
    {
      
      zipID:$zipID
      todayID:$todayID
      homeID:$homeID
      dayID:$dayID
      categoryID:$categoryID
     
     	
      categories:{connect:{
        where: {
          node:{categoryID:$categoryID}
        }
      }}
      
      homes:{connect:{
        where: {
          node:{uid:$homeID}
        }
        
      }}
      
      territories:{connect: {
        where: {
          node:{territoryID:$territoryID}
        }
       
      }}
      
     zips:{connect:{
        where:	{
          node:{zipID:$zipID}
        }
        
      }}
      
      
      
     todays:{connect:{
        where:	{
          node:{todayID:$todayID}
        }
       
      }}

      days:{connect:{
        where:	{
          node:{dayID:$dayID}
        }
       
      }}
      
    	
      
      
     
       
    }
    
    
    
  ) 
  
  {
   eventHomeCategories{
     homeID
   categoryID
    date_create
    
    homes{
      homeID
    }
      categories{
        category
        image_path
      }
        zips{
          zip
        }
        territories{
          territoryID
          territory
        }
   days{
    dayID
    date
  }
   
    todays{
      todayID
      day{
        dayID
      }
    }
  }
  }
}
   
 

    

  
`;


@Component({
  selector: 'app-offering-detail-modal',
  templateUrl: './offering-detail-modal.page.html',
  styleUrls: ['./offering-detail-modal.page.scss'],
})
export class OfferingDetailModalPage implements OnInit {

  uid = this.authService.getUid(); 
  
  offerings: any[];
  loading = true;
  error: any;
  dataReturned: any;
  todays: any[];
  homes: any[];
 

  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let offeringUID = this.navParams.get('offeringUID');
    let territory_number = this.navParams.get('territory_number');
    let territoryID = this.navParams.get('territoryID');
    let todayID = this.navParams.get('todayID');
    let zipID = this.navParams.get('zipID');
    let dayID = this.navParams.get('dayID');
    console.log('dayID',dayID);
    console.log('zipID',zipID);
    console.log('offeringUID',offeringUID);
    console.log('todayID',todayID);
    console.log('territory_number',territory_number);
    console.log('territoryID',territoryID);

    this.apollo
    .watchQuery({
      query: gql`
         query homes($uid:ID)
{
homes(where:{uid:$uid}){
  homeID
  username
  password
  email
  homeZip{
    zip
    zipID
    town
    zipTerritory{
      territoryID
      territory
      territory_number
      business_type{
            businessTypeID
            image_path
            business_type
              category{
                category
                categoryID
                  subcategory{
                    subcategory
                    subcategoryID
                      item{
                        itemID
                        item
                        image_path
                      }
                  }
                
              }
          }
    }
    
    tasks{
      taskID
      territoryID
      task_description
      task_detail
      homeID
      date_create
      taskInvites{
        company
        supplierID
        
        
      }
      taskSuppliers{
        email
        supplierID
       
      }
      taskConnects{
        supplierID
        company
        insurance
        license
      }
    }
    
      territory{
        territory
        territoryID
        territory_number
          classification{
            classification
              business_type{
                businessTypeID
                business_type
                  category{
                    category
                    categoryID
                      subcategory{
                        subcategory
                        subcategoryID
                          item{
                            itemID
                            item
                            image_path
                          }
                      }
                  }
              }
          }
          business_type{
            businessTypeID
            business_type
              category{
                category
                categoryID
                  subcategory{
                    subcategory
                    subcategoryID
                      item{
                        itemID
                        item
                        image_path
                      }
                  }
                
              }
          }
      }
  }
}
}


      `,
             //variables: {uid:this.uid} , 
         variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)

    this.apollo
    .watchQuery({
      query: gql`
         query todays
{
todays{
  todayID
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banners_place{
              territory_number
              bannerID
              banners{
                headline
              }
            }
            promoss_place{
              territory_number
              promoID
              promo{
                promotion
              }
            }
  }
  }
}


      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)

  
  this.apollo
  .watchQuery({
    query: gql`
      query offerings($offeringUID:ID)
{
offerings(where:{offeringUID:$offeringUID,}){
  offeringUID
 offering
 description
 image_path
  category{
    categoryID
    image_path
    image_path_header
    category
  }
  offering_category{
    categoryID
    category_image_path
    category
  }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {offeringUID: offeringUID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.offerings = result?.data?.offerings;
    this.loading = result.loading;
    this.error = result.error;
  });



  }
async openModalCategory(categoryID, territory_number,todayID,territoryID, zipID) {
  const modal = await this.modalController.create({
    component: CategoryHomePage,
    id:"categoryhomepage",
    componentProps: {
      
      uid:this.uid,
      categoryID:  categoryID,
      todayID:todayID,
      territory_number:territory_number,
      territoryID:territoryID,
      zipID: zipID
      
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
closeModal():void{
  this.modalController.dismiss();
}
async createCategoryEvent(categoryID,territoryID,zipID,todayID,uid,dayID) {
     
  this.apollo.mutate({
    mutation: createCategoryEvent,
    
    variables: {
   // homeID: this.afAuth.auth.currentUser.uid,
    homeID:uid,
    categoryID:categoryID,
    territoryID:territoryID,
    zipID:zipID,
    todayID:todayID,
   
    dayID:dayID
   
    
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
     

   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
   
  
  });
}
}

