import { Component, OnInit } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular'
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import { LocationPage } from '../location/location.page';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';


const createHomeTypeHomes = gql`
  mutation createHomeTypeHomes($hometypeID:ID,
                                $uid: Int,
                                $home_type: String,
    														$description: String,
    														$homeAddressID: ID,
    														$homeID:ID,
                                $locationID:ID,
    														$h30:String,
                                $h31:String,   
																$h32:String,
    														$h33:String,
    														$h34:String,
    														$h35:String,
    														$h36:String,
    														$h37:String,
    														$h38:String,
    														$h39:String,
    														$h310:String,
){
  createHomeTypeHomes(input:
    {
      hometypeID: $hometypeID
      uid:$uid
      home_type:$home_type
      description:$description
      homeAddressID:$homeAddressID
      homeID:$homeID
      locationID:$locationID
      h30:$h30
      h31:$h31
      h32:$h32
      h33:$h33
      h34:$h34
      h35:$h35
      h36:$h36
      h37:$h37
      h38:$h38
      h39:$h39
      h310:$h310

      
      
     
      home:{connect:{
        where: {
          node:{homeID:$homeID}
        }
       
      }}
      
      home_address:{connect:{
        where: {
          node:{homeAddressID:$homeAddressID}
        }
       
      }}
      hometypes:{connect:{
        where: {
          node:{hometypeID:$hometypeID}
        }
       
      }}
       
      
      
     
    }
    
  ) 
  
  {
   homeTypeHomes{
     hometypehomeID
    hometypeID
    uid
    home_type
    description
    homeAddressID
    homeID
    locationID
    h30
    h31
    h32
    h33
    h34
    h35
    h36
    h37
    h38
    h39
    h310
    home{
      homeID
    }
    home_address{
      homeAddressID
    }
    hometypes{
      hometypeID
      description
    }
  }
  }
}
                
  
`;
@Component({
  selector: 'app-home-type',
  templateUrl: './home-type.page.html',
  styleUrls: ['./home-type.page.scss'],
})
export class HomeTypePage implements OnInit {

  uid = this.authService.getUid(); 
homeTypes:any[];
loading = true;
error: any;
dataReturned: any;
hometypeID: any;
locationID: any;





constructor(private afAuth: AngularFireAuth,
  private authService: AuthService, 
  private navService: NavService,
  private apollo: Apollo, 
  private router: Router,
  private modalController: ModalController,
  private navParams: NavParams,
  public toastController: ToastController,
  private fb: FormBuilder,
  private activatedroute:ActivatedRoute
   
  ) { }

  ngOnInit() {
    let homeAddressID = this.navParams.get('homeAddressID');
    let homeID = this.navParams.get('homeID');
    //let locationID = this.navParams.get('locationID');
    let h30 = this.navParams.get('h30');
    let h31 = this.navParams.get('h31');
    let h32 = this.navParams.get('h32');
    let h33 = this.navParams.get('h33');
    let h34 = this.navParams.get('h34');
    let h35 = this.navParams.get('h35');
    let h36 = this.navParams.get('h36');
    let h37 = this.navParams.get('h37');
    let h38 = this.navParams.get('h38');
    let h39 = this.navParams.get('h39');
    let h310 = this.navParams.get('h310');





    this.apollo
      .watchQuery({
      query: gql`
      query homeTypes
      {
        homeTypes{
        home_type
        hometypeID
        description
    
  }
}

    `,
           //variables: {uid:this.uid} , 
       
       
  })
  .valueChanges.subscribe((result: any) => {
    this.homeTypes = result?.data?.homeTypes;
    this.loading = result.loading;
    this.error = result.error;
  });
  }
  async createHomeTypeHomes(hometypeID,uid,homeAddressID,homeID,locationID,h30,h31,h32,h33,h34,h35,h36,h37,h38,h39,h310) {
    this.apollo.mutate({
      mutation: createHomeTypeHomes,
      
      variables: {
      hometypeID:this.hometypeID,
      uid:uid,
      homeAddressID:homeAddressID,
      homeID:homeID,
      locationID:this.locationID,
      h30:h30,
      h31:h31,
      h32:h32,
      h33:h33,
      h34:h34,
      h35:h35,
      h36:h36,
      h37:h37,
      h38:h38,
      h39:h39,
      h310:h310,
      
      
      
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       //console.log('boundary1',this.boundary1)

  console.log("Form Submitted!");
  this.router.navigateByUrl('seller/entry')
  
  //this.router.navigateByUrl('seller/home-type');
  //this.router.navigateByUrl('seller/entry');

  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
     
    });
   }
  
  closeModalHomeType():void{
    //this.modalController.dismiss();
    this.modalController.dismiss(null, null, "home-type");
  }
}
