import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router'
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { BasePage } from '../base/base.page';


const createMysubscriptions = gql`
 mutation createMysubscriptions(
												$planID:ID,$uid:ID,$baseID:ID
  											
  											
){
  createMysubscriptions(input:
    {
     planID:$planID
     uid:$uid
     baseID:$baseID
     
      is_active: true
    	supplier:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      plan:{connect:{
        where: {
          node:{planID:$planID}
        }
        
      }}
      base:{connect:{
        where: {
          node:{baseID:$baseID}
        }
        
      }}
    }
    
    
    
  ) 
  
  {
   mysubscriptions{
     	subscriptionID
     	planID
      baseID
      uid
    	supplierID
    	supplierUID
    	date_create
    	is_active
      
      	supplier{
         supplierID
         uid
          
        }
    plan{
      planID
      plan_number
      amount
      business_type
      amount
    }
    base{
      baseID
    }
    
        }
    
  }
  
 
    }
    


    
     
    
  
`;

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.page.html',
  styleUrls: ['./subscription-detail.page.scss'],
})
export class SubscriptionDetailPage implements OnInit {
  suppliers: any[];
  Suppliers: any[];
  plans: any[];
  Plans: any[];
  subscriptions: any[];
  subscriptionID: any[];
  subscriptionsUID: any[];
  mysubscriptions:any[];
  Subscriptions: any[];
  email: string[];
  loading = true;
  error: any;
  dataReturned: string[];
  planID:any;
  uid = this.authService.getUid(); 
  bases: any[];


  constructor(private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router, private activatedroute:ActivatedRoute,private modalController: ModalController, private navParams: NavParams, public toastController: ToastController, ) {}

  ngOnInit() {
    //let planID = this.activatedroute.snapshot.paramMap.get('planID');
    let planID = this.navParams.get('planID');

    this.apollo
    .watchQuery({
      query: gql`
         query bases($uid:ID) {
       bases(where:{uid:$uid}) 
      {
            baseID
            uid
    baseSubscription{
      subscriptionID
    }
    basePlan{
      planID
      description
      territory
      business_type
    
    }
  
  
}
}
      `, 
          variables:    {uid: this.afAuth.auth.currentUser.uid},
        //variables:    {uid:uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.bases = result?.data?.bases;
      this.loading = result.loading;
      this.error = result.error;
    });
    this.apollo
      .watchQuery({
        query: gql`
           query Supplier($uid:ID) {
          suppliers(where:{uid:$uid}) 
        {
              supplierID
              uid
              email
              password
              username
              count_subscription_plan
                supplierBase{
                  baseID
                }
    
    
  }
}
        `, 
           variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.suppliers = result?.data?.suppliers;
        this.loading = result.loading;
        this.error = result.error;
      });

      this.apollo
      .watchQuery({
        query: gql`
           query Plans($planID:ID) {
          plans(where:{planID:$planID}) 
        {
          planID
          plan_number
          description
          territory
          business_type
          amount
  }
}
        `,
          //variables: {planID:this.activatedroute.snapshot.paramMap.get('planID')} ,
           variables: {planID:planID} ,
      })
      .valueChanges.subscribe((result: any) => {
        this.plans = result?.data?.plans;
        this.loading = result.loading;
        this.error = result.error;
      });
      this.modalController.dismiss(null, null, "subscription");
      //this.router.navigateByUrl('buyer/base');
    }
    closeModal():void{
      this.modalController.dismiss(null, null, "subscription");
      
    }





      async createMysubscriptions(baseID) {
        
        
        this.apollo.mutate({
          mutation: createMysubscriptions,
          
          variables: {
              uid: this.afAuth.auth.currentUser.uid,
              planID:this.planID,
              baseID:baseID
         
          
          
           
        },
          
        }).subscribe(({ data }) => {
          
       
           console.log(data); 
            
          console.log("Form Submitted!");
      
         
    
         
        }, (error) => {
          console.log('there was an big ass error sending the query', error);
          
         
          
        });
        
        
        this.modalController.dismiss(null, null, "subscription");
       
       
  }
  showToast() {
    this.toastController.create({
      header: 'Congratulation',
      message: 'You Have Now Created Your Subscription, Please select Territory & Business Type',
      position: 'middle',
      cssClass: 'my-custom-class',
      color: 'home',
      duration: 6000
      
    }).then((obj) => {
      obj.present();
      
     
    });
    
}
async openModal(uid) {
  const modal = await this.modalController.create({
    component: BasePage,
    id:"base",
    componentProps: {
      
      uid:this.uid,
     // baseID:baseID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
}